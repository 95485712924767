import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import theme from "../styles/theme";

const NewsPage = ({ location, data }) => {
  const seo = {
    tags: [
      {
        tagName: "title",
        content: "News",
      },
    ],
  };
  return (
    <Layout location={location} seo={seo} logo={data.global.logo}>
      <main
        css={`
          margin: 0 auto;
          padding-bottom: 0;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          padding-top: 100px;
          ${theme.tablet} {
            padding-top: 40px;
          }
        `}
      >
        <section
          css={`
            margin-top: 100px;
            flex-direction: column;
            margin-bottom: 100px;
          `}
        >
          <div
            css={`
              display: grid;
              justify-content: center;
              grid-gap: 20px;
              grid-template-columns: repeat(3, 1fr);
              ${theme.tablet} {
                grid-template-columns: repeat(2, 1fr);
              }
              ${theme.max600} {
                grid-template-columns: repeat(1, 1fr);
                > div:not(:last-child) {
                  border-right: none !important;
                }
              }
              > div {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
                padding-right: 30px;
                padding-bottom: 60px;
                margin-bottom: 45px;
                box-sizing: border-box;
                position: relative;
                &:before {
                  content: "";
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  height: 1px;
                  width: 60%;
                  border-bottom: 1px solid ${theme.pink};
                }
                h3 {
                  color: ${theme.grey};
                  margin: 0;
                  font-weight: 900;
                  font-size: 24px;
                  line-height: 110.4%;
                  margin-bottom: 20px;
                }
                p {
                  font-size: 18px;
                  line-height: 118%;
                  margin: 0 0 25px;
                }
                .date {
                  margin: 0 0 20px;
                }
                .button {
                  background: ${theme.pink};
                  color: #fff;
                }
              }
            `}
          >
            {data.news.edges.map(item => {
              return (
                <div>
                  <h3>{item.node.title}</h3>
                  <span className="date">{item.node.published}</span>
                  <p>{item.node.excerpt}</p>
                  <div>
                    <a className="button" href={`/news/${item.node.slug}`}>
                      Read more
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default NewsPage;

export const query = graphql`
  query NewsQuery {
    news: allDatoCmsArticle(sort: { fields: [published], order: DESC }) {
      edges {
        node {
          title
          excerpt
          slug
          published(formatString: "DD.MM.YYYY", locale: "en")
        }
      }
    }
    global: datoCmsGlobal {
      logo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
  }
`;
